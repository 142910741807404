import { categories } from "@vueuse/core/metadata.cjs";

export const DATE_FORMAT       = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';//YYYY-MM-DDTHH:mm:ss.SSS[Z]
export const DB_DATE_FORMAT    = 'YYYY-MM-DD HH:mm:ssZ';
export const DATE_FORMAT_INPUT = 'DD.MM.YYYY HH:mm';
export const DATE_ONLY_FORMAT  = 'YYYY-MM-DD';
export const PRELOAD_DAYS_BUFFER = 7;


/** Work data for tiles/bars. Default `duration` in minutes */
export const TOOLBOX_ITEMS = [
  { type: 'transport',                     category: 'order',         lane: 'order',   keys: [{key: 'orderId', value: 'Id'}],                                            color: 'orange',      icon: 'mdi-text-box',                       duration: 1440 },
  { type: 'service',                       category: 'order',         lane: 'order',   keys: [],                                                                         color: 'orange',      icon: 'mdi-tow-truck',                      duration: 1440 },
  { type: 'load',                          category: 'action',        lane: 'action',  keys: [],                                                                         color: 'green',       icon: 'mdi-tray-arrow-down',                duration:   60 },
  { type: 'unload',                        category: 'action',        lane: 'action',  keys: [],                                                                         color: 'green',       icon: 'mdi-tray-arrow-up',                  duration:   60 },
  { type: 'transport',                     category: 'action',        lane: 'action',  keys: [],                                                                         color: 'green',       icon: 'mdi-checkbox-marked-circle-outline', duration:   60 },
  { type: 'empty',                         category: 'action',        lane: 'action',  keys: [],                                                                         color: 'amber',       icon: 'mdi-map-marker-distance',            duration:   60 },
  { type: 'truck',                         category: 'vehicle',       lane: 'vehicle', keys: [{key: 'vehicleId', value: 'Id'}],                                          color: 'green',       icon: 'mdi-truck',                          duration: 1440 },
  { type: 'semi-truck',                    category: 'vehicle',       lane: 'vehicle', keys: [{key: 'vehicleId', value: 'Id'}],                                          color: 'green',       icon: 'mdi-truck-flatbed',                  duration: 1440 },
  { type: 'semi-trailer',                  category: 'vehicle',       lane: 'vehicle', keys: [{key: 'vehicleId', value: 'Id'}],                                          color: 'green',       icon: 'mdi-truck-trailer',                  duration: 1440 },
  { type: 'repair',                        category: 'vehicle',       lane: 'vehicle', keys: [{key: 'vehicleId', value: 'Id'}],                                          color: 'deep-orange', icon: 'mdi-car-wrench',                     duration: 1440 },
  { type: 'empty',                         category: 'vehicle',       lane: 'vehicle', keys: [{key: 'vehicleId', value: 'Id'}],                                          color: 'amber',       icon: 'mdi-map-marker-distance',            duration: 1440 },
  { type: 'drive',                         category: 'driver',        lane: 'driver',  keys: [{key: 'driverId',  value: 'Id'}],                                          color: 'blue',        icon: 'mdi-account',                        duration: 1440 },
  { type: 'rest',                          category: 'driver',        lane: 'driver',  keys: [{key: 'driverId',  value: 'Id'}],                                          color: 'blue',        icon: 'mdi-account',                        duration: 1440 },
  { type: 'transport',                     category: 'driver',        lane: 'driver',  keys: [{key: 'driverId',  value: 'Id'}],                                          color: 'blue',        icon: 'mdi-account',                        duration: 1440 },
  { type: 'technical maintenance',         category: 'repair',        lane: 'action',  keys: [{key: 'repairId',  value: 'Id'}, {key: 'vehicleId', value: 'vehicle.Id'}], color: 'deep-orange', icon: 'mdi-car-wrench',                     duration: 1440 },
  { type: 'period maintenance inspection', category: 'repair',        lane: 'action',  keys: [{key: 'repairId',  value: 'Id'}, {key: 'vehicleId', value: 'vehicle.Id'}], color: 'deep-orange', icon: 'mdi-car-wrench',                     duration: 1440 },
  { type: 'fire extinguisher inspection',  category: 'repair',        lane: 'action',  keys: [{key: 'repairId',  value: 'Id'}, {key: 'vehicleId', value: 'vehicle.Id'}], color: 'deep-orange', icon: 'mdi-car-wrench',                     duration: 1440 },
  { type: 'tire replacement',              category: 'repair',        lane: 'action',  keys: [{key: 'repairId',  value: 'Id'}, {key: 'vehicleId', value: 'vehicle.Id'}], color: 'deep-orange', icon: 'mdi-car-wrench',                     duration: 1440 },
  { type: 'transport',                     category: 'trailerLoaded', lane: 'vehicle', keys: [{key: 'vehicleId', value: 'Id'}, {key: 'orderId', value: 'orderId'}],      color: 'amber',       icon: 'mdi-truck-trailer',                  duration: 1440 },
]

export const TIMELINE_TASKS = [
  { type: 'transport',     category: 'order',    catType: null,           color: 'orange',      icon: 'mdi-text-box',            duration: 1440,  main: true },
  { type: 'service',       category: 'order',    catType: null,           color: 'orange',      icon: 'mdi-tow-truck',           duration: 1440,  main: true },
  { type: 'load',          category: 'action',   catType: null,           color: 'green',       icon: 'mdi-tray-arrow-down',     duration:   60              },
  { type: 'unload',        category: 'action',   catType: null,           color: 'green',       icon: 'mdi-tray-arrow-up',       duration:   60              },
  { type: 'transport',     category: 'action',   catType: null,           color: 'green',       icon: 'mdi-play',                duration:   60              },
  { type: 'empty',         category: 'action',   catType: null,           color: 'amber',       icon: 'mdi-map-marker-distance', duration:   60              },
  { type: 'repair',        category: 'action',   catType: null,           color: 'deep-orange', icon: 'mdi-car-wrench',          duration: 1440              },
  { type: 'transport',     category: 'vehicle',  catType: 'truck',        color: 'green',       icon: 'mdi-truck',               duration: 1440              },
  { type: 'transport',     category: 'vehicle',  catType: 'semi-truck',   color: 'green',       icon: 'mdi-truck-flatbed',       duration: 1440              },
  { type: 'transport',     category: 'vehicle',  catType: 'semi-trailer', color: 'green',       icon: 'mdi-truck-trailer',       duration: 1440              },
  { type: 'transport',     category: 'vehicle',  catType: 'trailer',      color: 'green',       icon: 'mdi-truck-trailer',       duration: 1440              },
  { type: 'repair',        category: 'vehicle',  catType: null,           color: 'deep-orange', icon: 'mdi-car-wrench',          duration: 1440              },
  { type: 'empty',         category: 'vehicle',  catType: null,           color: 'amber',       icon: 'mdi-map-marker-distance', duration: 1440              },
  { type: 'drive',         category: 'driver',   catType: null,           color: 'blue ',       icon: 'mdi-account',             duration: 1440,  main: true },
  { type: 'rest',          category: 'driver',   catType: null,           color: 'blue',        icon: 'mdi-account-clock',       duration: 1440              },
]

export const BAR_CATEGORIES = [
  { category: 'order',    color: 'orange',      icon: 'mdi-text-box'    },
  { category: 'vehicle',  color: 'green',       icon: 'mdi-truck'       },
  { category: 'driver',   color: 'blue',        icon: 'mdi-account'     },
  { category: 'action',   color: 'deep-orange', icon: 'mdi-play-circle' },
]

/** `scale, precision: ENUM`, `scaleDays, precisionDays: Number` - days up to */
export const SCALES = [
  { scale: 'day'  , scaleDays:   1, precision: 'hour' , precisionDays: 0                  },
  { scale: 'week' , scaleDays:   7, precision: 'day'  , precisionDays: 4, isDefault: true },
  { scale: 'month', scaleDays:  31, precision: 'week' , precisionDays: 46                 },
  { scale: 'year' , scaleDays: 365, precision: 'month', precisionDays: 124                },
]

/** Snap factor (step) in minutes */
export const SNAPS = [// snapPrecisions, measure
  { id: 'none'  , value: null , title: 'Off'     },
  { id: 'mins10', value: 10   , title: '10 mins' },
  { id: 'mins15', value: 15   , title: '15 mins' },
  { id: 'mins30', value: 30   , title: '30 mins' },
  { id: 'hour'  , value: 60   , title: '1 Hour'  },
  { id: 'hours6', value: 360  , title: '6 hours' },
  { id: 'day'   , value: 720  , title: '1 Day'   },
  { id: 'week'  , value: 4320 , title: '1 Week'  },
  { id: 'month' , value: 17280, title: '4 weeks' },
]

/** States [ENUM] */
export const STATES = [
  { id: 'OVERLAP',       category: 'problem', class: 'warning', title: 'Overlap'       , icon: 'mdi-alert' },
  { id: 'OVERLAP_SAME',  category: 'problem', class: 'error',   title: 'Overlap Same'  , icon: 'mdi-alert-rhombus' },
  { id: 'OVERLAP_COURSE',category: 'problem', class: 'warning', title: 'Overlap Course', icon: 'mdi-alert' },
  { id: 'COMPLETED',   category: 'state', class: 'COMPLETED',   title: 'Completed' },
  { id: 'PLANNED',     category: 'state', class: 'PLANNED',     title: 'Planned'  },
  { id: 'IN_PROGRESS', category: 'state', class: 'IN_PROGRESS', title: 'In Progress' }
]